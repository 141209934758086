
import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../../components/settings/index.js';
import {useTable, TableHeadCustom_1, TablePaginationCustom} from 'src/components/table/index.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../components/snackbar/index.js';
import Iconify from '../../../components/iconify/iconify.js';
import AuditingTableRow from './auditing-table-row.js';
import AuditingToolBar from './auditing-toolbar.js';
import Label from '../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import { useBoolean } from '../../../hooks/use-boolean.js';

import AuditingFormDialog from './create-auditing-form.js';


// GRAPHQL
import {GET_AUDITINGFORM, CREATE_AUDITINGFORM, DELETE_AUDITINGFORM, UPDATE_AUDITINGFORM} from '../../../graphql/modules/_client/auditingForm.js';
import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
]
// const TABLE_HEAD = [
//   {id: 'tsgRefNumber', label: 'TGS Course Reference Number'},
//   {id: 'courseTitle', label: 'Course Title'},
//   {id: 'modeOfTraining', label: 'Mode of Training'},
//   {id: 'mediumInstruction', label: 'Medium of Instruction'},
//   {id: 'approvalDate', label: 'Valid From (Date)'},
//   {id: 'validUntil', label: 'Valid To (Date)'},
//   {}
// ]

// const defaultFilters = {
//   name: '',
//   subscription: [],
//   status: 'all'
// }

export function AuditingListView({formInfo}) {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const company  = useSelector((state) => state?.auth?.company);
  const [isCreate, setCreate] = useState(true);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [idToUpdate, setIdToUpdate] = useState("");
  const {companyName, databaseName}  = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const [tableHead, setTableHead] = useState([])
  const [dataField, setDataField] = useState(() => state?.data?.section || []);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  // const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);

  const confirm = useBoolean();

  const [CreateAuditingFormMutation] =  useMutation(CREATE_AUDITINGFORM);
  const [UpdateAuditingFormMutation] =  useMutation(UPDATE_AUDITINGFORM);
  const [DeleteAuditingFormMutation] =  useMutation(DELETE_AUDITINGFORM);

  
  

  const { data, loading, error, refetch } = useQuery(GET_AUDITINGFORM, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: state?.databaseName || company,
      formModel: state?.data?.formModel,
      order: table.order || 'asc',
      orderBy: table.orderBy || 'courseTitle',
      },
      fetchPolicy: "cache-and-network",
    });


    useEffect(() => {
      // const _dataField = state?.data?.section?.flatMap(s => s.components);
      const components = state?.data?.section?.flatMap(s => s.components).filter(item => item?.showIntable);
      setTableHead(components)

      if (state?.data?.section) {
        setDataField(state.data.section);
      }

      if(data){
        setResults(data?.getAuditingForm)
      }
  }, [data, state?.data?.section, dataToUpdate])




  // const handleChangeText = useCallback((event) => {
  //   const { name, value } = event.target;
  //   setDataField(prevFields => {
  //     return prevFields.map(field => 
  //       field.name === name ? { ...field, value } : field
  //   );
  //   })
  // })

  const handleChangeText = useCallback((sectionName, fieldName, value) => {
    setDataToUpdate(prev => ({
      ...prev,
      [sectionName]: {
        ...(prev?.[sectionName] ?? {}), // Fallback to empty object if undefined
        [fieldName]: value
      }
    }));
  });
  
  
  

  const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  

    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );


   const handleSave = async() => {
    // const formData = dataField.reduce((acc, section) => {
    //   section.components?.forEach((field) => {
    //     acc[field.name] = field.value;  // Add each field value to the object
    //   });
    //   return acc;
    // }, {});

     try {
       const {data: { createAuditingForm }} = await CreateAuditingFormMutation({
         variables: {
           companyName: databaseName,
           fullName: fullName,
           formModel: state?.data?.formModel,
             data: {
               section: dataToUpdate?.section,
               createdBy: fullName,
               createdDate: new Date(),
               modifiedBy: fullName,
               modifiedDate: new Date()
             }
           }
       })
       if(createAuditingForm?.success){
         enqueueSnackbar('Create success!', {variant: 'success'});
         refetch();
       }else {
         enqueueSnackbar('Failed to created', {variant: 'error'});
       }
 
     } catch(err){
       console.log(err)
     }
   }

  const handleDeleteRow = useCallback(async (id) => {
       const {data: { deleteAuditingForm }} = await DeleteAuditingFormMutation({
         variables: {
           _id: id,
           formModel: state?.data?.formModel,
           companyName: databaseName,
           fullName: fullName,
         }
       })
   
       if(deleteAuditingForm?.success){
         enqueueSnackbar('Deleted success!', {variant: 'success'});
         refetch();
       }else {
         enqueueSnackbar('Failed to delete!', {variant: 'error'});
       }
       },
       [refetch]
  );

  const handleUpdate = async() => {
    console.log(dataToUpdate?.section)
     try {
       const {data: { updateAuditingForm }} = await UpdateAuditingFormMutation({
         variables: {
           _id: idToUpdate,
           companyName: databaseName,
           fullName: fullName,
           formModel: state?.data?.formModel,
             data: {
               section: dataToUpdate?.section,
               modifiedBy: fullName,
               modifiedDate: new Date()
             }
           }
       })
       if(updateAuditingForm?.success){
         enqueueSnackbar('Update success!', {variant: 'success'});
         refetch();
       }else {
         enqueueSnackbar('Failed to created', {variant: 'error'});
       }
 
     } catch(err){
       console.log(err)
     }
   }




  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading={`${companyName} - ${state?.data?.title}`}
         links={[
          {name: 'Dashboard'},
          {name: 'Auditing Forms'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          onClick={() => {
            setDataToUpdate(null)
            setCreate(true);
            confirm.onTrue()
          }}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            Create
          </Button>
         }
        />


        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                >
                  {tab.value === 'all' && results?.count}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <AuditingToolBar onFilters={handleFilters} />
        <Scrollbar>

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
              {tableHead && (
                    <TableHeadCustom_1
                    headLabel={[...tableHead, { "value": "action", "label": "Action" }]}
                    order={table.order}
                    orderBy={table.orderBy}
                    onSort={table.onSort}
                  />
                )}
          
   
                <TableBody>

                {results?.results?.map((row) => (
                      <AuditingTableRow
                      key={row?.id}
                      row={row}
                      tableHead={tableHead}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => {
                        setDataToUpdate(row)
                        setIdToUpdate(row?._id)
                        setCreate(false);
                        confirm.onTrue()
                      }}
                    />
                ))}
                </TableBody>

              </Table>
          </TableContainer>
          </Scrollbar>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
 
        </Card>

        <AuditingFormDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title={isCreate ? `Create ${state?.data?.title}` : `Update ${state?.data?.title}`}
            section={dataField}
            isCreate={isCreate}
            handleChange={handleChangeText}
            dataToUpdate={dataToUpdate}
            action={
            <Button variant="contained" color="info" onClick={handleSave}>
                Save
             </Button>
            }
            actionUpdate={
            <Button variant="contained" color="info" onClick={handleUpdate}>
                 Update
             </Button>
             }
        />
        

    </Container>
  );
}

AuditingListView.propTypes = {
    formInfo: PropTypes.object,
};