import { Helmet } from "react-helmet-async";
import {AuditingListView} from "../../sections/auditing/audtingForm/index.js";

export default function AuditingManagementPage(){
    return (
        <>
            <Helmet>
                <title>Auditing List View</title>
            </Helmet>
            <AuditingListView />
        </>
    )
}