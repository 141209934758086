import {  useDroppable } from "@dnd-kit/core";
import { useState, useEffect } from "react";
import { Button, TextField, Checkbox, Card, Radio, Typography, IconButton, Grid, Stack, FormControlLabel, FormControl, FormGroup, FormLabel, MenuItem, RadioGroup } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'src/components/iconify/iconify';
import { useBoolean } from '../../hooks/use-boolean.js';
import FormBuilderProperties from "./form-builder-sectionproperties.js";
import FormBuilderCompProperties from "./form-builder-compproperties.js";


const formElements = [
    { id: "label", label: "Label", icon: "bi:textarea-t", name:"label" },
    { id: "input", label: "Text Input", icon: "solar:text-field-bold-duotone", name: "textfield", value: "Text Input", defaultValue: "Text Input", required: false, showIntable: false },
    { id: "multiInput", label: "Multiline Text Input", icon: "solar:text-field-bold-duotone", name: "textfield", value: "Text Input", defaultValue: "Text Input", required: false, showIntable: false },
    { id: "select", label: "Select", icon: "fluent-mdl2:dropdown", name: "select", required: false, option: ["value1", "value2", "value3"], showIntable: false },
    { id: "datepicker", label: "Date Picker", icon: "solar:calendar-date-bold-duotone", name: "datepicker", required: false, showIntable: false },
    { id: "checkbox", label: "Checkbox", icon: "material-symbols:select-check-box", name: "checkbox", required: false, isRow: true, option:[{value: "checkbox1", label: "checkbox1"}], showIntable: false },
    { id: "radio", label: "Radio Button", icon: "ri:radio-button-line", name: "Text Input", name: "radio", required: false, isRow: true, option: [{value: "option1", label: "option1"}, {value: "option2", label: "option2"}], showIntable: false },
    { id: "esignature", label: "Esignature", icon: "ph:signature-fill", name:"esignature" },
  ];



  const Section = ({ section, index, formFields, setFormFields }) => {
    const { setNodeRef } = useDroppable({ id: `section-${index}` });
    const [coIndex, setCoIndex] = useState(null);
    const confirm = useBoolean(false);
    const componentConfirm = useBoolean(false);

    const removeElementFromSection = (sectionIndex, compIndex) => {
        const updatedFields = [...formFields];
        updatedFields[sectionIndex].components.splice(compIndex, 1);
        setFormFields(updatedFields);
    };

    const handleProperties = () => {
        confirm.onTrue();
    }

    const handleComponentProperties = (compIndex) => {
      setCoIndex(compIndex)
      componentConfirm.onTrue()
    }


  
    return (
        <>
      <Card className="p-4 bg-gray-200 shadow rounded" ref={setNodeRef}>
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }} justifyContent="space-between">
    <Typography variant="h6">{section.label}</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
                onClick={() => handleProperties()}
                    color="primary"
                >
                    <Iconify icon="solar:pen-new-square-linear" />
                </IconButton>
                <IconButton
                onClick={() => setFormFields(formFields.filter((_, i) => i !== index))}
                color="error"
                >
                <Iconify icon="solar:trash-bin-2-bold-duotone" />
                </IconButton>
            </Stack>
            </Stack>
        <Grid container spacing={2}>
          {section.components.map((field, compIndex) => (
            <Grid item xs={section?.column} key={compIndex}>
                  <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >
                {field.type === "label" && <Typography variant="outlined" fullWidth size="small" placeholder={field.label}>{field.label}</Typography>}
                {field.type === "input" && <TextField variant="outlined" fullWidth size="small" placeholder={field.label + (field?.required ? " *" : "")} required={field?.required}/>}
                {field.type === "multiInput" && <TextField multiline variant="outlined" rows={field.rows} fullWidth size="small" placeholder={field.label + (field?.required ? " *" : "")}/>}
                {field.type === "select" && 
                      <TextField select variant="outlined" fullWidth size="small" placeholder={field.label + (field?.required ? " *" : "")} required={field?.required}>
                          {
                            field?.option?.map((res, ind) => (
                              <MenuItem key={ind} value={res}>{res}</MenuItem>
                            ))
                          }
                      </TextField>
                  }
                {field.type === "checkbox" && 
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                         <FormLabel component="legend">{field?.label}</FormLabel>
                         <FormGroup name={field?.name}  row={field?.isRow ? true : false}>
                          {
                            field?.option?.map((res, ind) => (
                              <FormControlLabel key={ind} control={ <Checkbox value={res?.value} /> } label={res?.label} />
                            ))
                          }
                       
                         </FormGroup>
                    </FormControl>
                }
                {field.type === "radio" &&
                
                    <FormControl>
                    <FormLabel id="radio-buttons-group-label">{field?.label}</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      name={field?.name}
                      row={field?.isRow ? true : false}
                    >
                      {
                        field?.option?.map((res, ind) => (
                          <FormControlLabel value={res?.value} control={<Radio />} label={res?.label} />
                        ))
                      }
                    
                    </RadioGroup>
                  </FormControl>

                }
                {field.type === "button" && <Button variant="contained" fullWidth>{field.label}</Button>}
                {field.type === "datepicker" &&
                <DatePicker
                  label={field.label}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      
                    }
                  }}
                  sx={{width: 410}}
                  renderInput={(params) => <TextField {...params} />}
                />}

              {field.type === "esignature" && <Typography variant="outlined" fullWidth size="small" placeholder={field.label}>{field.label}</Typography>}
                {/* <TextField
                  variant="outlined"
                  size="small"
                  value={field.label}
                  onChange={(e) => {
                    const updatedFields = [...formFields];
                    updatedFields[index].label = e.target.value;
                    setFormFields(updatedFields);
                  }}
                /> */}

                <IconButton
                    onClick={() => handleComponentProperties(compIndex)}
                  color="primary"
                >
                  <Iconify icon="solar:pen-bold-duotone" />
                </IconButton>

                <IconButton
                   onClick={() => removeElementFromSection(index, compIndex)}
                  color="error"
                >
                  <Iconify icon="solar:trash-bin-2-bold-duotone" />
                </IconButton>
                </Stack>
            </Grid>
          ))}
        </Grid>
  
      </Card>

                 <FormBuilderProperties
                    open={confirm.value}
                     onClose={confirm.onFalse}
                     section={section}
                     index={index}
                     formFields={formFields}
                     setFormFields={setFormFields}
                    title="Create Auditing Form"
                    content={`Are you sure want to delete?`}
                     action={
                      <Button variant="contained" color="info" >
                        Save
                     </Button>
                   }
                  />

                  <FormBuilderCompProperties
                    open={componentConfirm.value}
                     onClose={componentConfirm.onFalse}
                     section={section}
                     index={index}
                     coIndex={coIndex}
                     formFields={formFields}
                     setFormFields={setFormFields}
                    title="Create Auditing Form"
                    content={`Are you sure want to delete?`}
                     action={
                      <Button variant="contained" color="info" >
                        Save
                     </Button>
                   }
                  />
      </>
    );
  };


const DroppableCanvas = ({ formFields, setFormFields, addSection }) => {
    const { setNodeRef } = useDroppable({ id: "canvas" });
  
    return (
      <div ref={setNodeRef} className="w-full h-96 p-4 border-2 border-dashed">
        {formFields?.length === 0 && <Typography color="textSecondary">Please add section to start.</Typography>}
        <Grid container spacing={2}>
          {formFields?.map((field, index) => (
            <Grid item xs={12} key={index}>
              {field.type === "section" ? (
                <Section section={field} index={index} formFields={formFields} setFormFields={setFormFields} />
              ) : null}
            </Grid>
          ))}
        </Grid>
        <Button onClick={addSection} variant="contained" sx={{mt: 5}} startIcon={<Iconify icon="solar:clipboard-add-bold-duotone" />}>Add Section</Button>
      </div>
    );
  };
  

  export {
    DroppableCanvas,
    formElements
  };