import gql from "graphql-tag";

export const GET_CUSTOMFORM = gql`
query GetCustomForm($companyName: String!, $encryptionkey: String!) {
    getCustomForm(companyName: $companyName, encryptionkey: $encryptionkey) {
        code
        success
        message
        count
        results
  }
}`;

export const GET_CUSTOMFORM_BY_ID = gql`
query GetCustomFormById($companyName: String!, $encryptionkey: String!, $_id: ID!) {
    getCustomFormById(companyName: $companyName, encryptionkey: $encryptionkey, _id: $_id) {
        code
        success
        message
        count
        results
  }
}`;

export const CREATE_CUSTOMFORM = gql`
mutation CreateCustomForm($data: customInput!, $fullName: String!, $companyName: String!, $encryptionkey: String!) {
  createCustomForm(data: $data, fullName: $fullName, companyName: $companyName, encryptionkey: $encryptionkey) {
    code
    success
    message
  }
}`;

export const UPDATE_CUSTOMFORM = gql`
mutation UpdateCustomForm($_id: ID!, $data: customInput!, $fullName: String!, $companyName: String!, $encryptionkey: String!) {
  updateCustomForm( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, encryptionkey: $encryptionkey) {
    code
    success
    message
  }
}`;

export const DELETE_CUSTOMFORM= gql`
mutation DeleteCustomForm($_id: ID!, $fullName: String!, $companyName: String!, $encryptionkey: String!) {
  deleteCustomForm(_id: $_id, fullName: $fullName, companyName: $companyName, encryptionkey: $encryptionkey) {
    code
    success
    message
  }
}`;