import * as React from 'react';
import {Button, Dialog, DialogContent, DialogActions, AppBar, Toolbar, IconButton, Slide, TextField, Checkbox, FormControlLabel, RadioGroup, Typography, Grid, Stack} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function FormBuilderCompProperties({title, content, action, open, section, index, coIndex, formFields, setFormFields, onClose, ...other }) {

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields(prevFields => {
      const updatedFields = [...prevFields];
      updatedFields[index].components[coIndex] = {
          ...updatedFields[index].components[coIndex], 
          [name]: value  
      };
      return updatedFields;
  });
  }

  const handleChangeChecked = (event) => {
    const { name, type, value, checked } = event.target;
  
    setFormFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].components[coIndex] = {
        ...updatedFields[index].components[coIndex],
        [name]: type === "checkbox" ? checked : value, // Use checked for checkboxes
      };
      return updatedFields;
    });
  };

  const MultiTextField = (
    <Grid item xs={12}>
      <TextField variant="outlined" name="rows" value={formFields[index]?.components[coIndex]?.rows} fullWidth size="small" placeholder='Row' onChange={(e) => handleChange(e)}/>
  </Grid>
  )


// HANDLE SELECT
 const handleDeleteOption = (optIndex) => {
    setFormFields(prevFields => {
      return prevFields.map((field, fieldIndex) =>
        fieldIndex === index
          ? {
              ...field,
              components: field.components.map((component, compIndex) =>
                compIndex === coIndex
                  ? {
                      ...component,
                      option: component.option.filter((_, oIndex) => oIndex !== optIndex) // Remove option
                    }
                  : component
              )
            }
          : field
      );
    });
  };

  const handleAddOption = () => {
    setFormFields(prevFields => {
      return prevFields.map((field, fieldIndex) =>
        fieldIndex === index
          ? {
              ...field,
              components: field.components.map((component, compIndex) =>
                compIndex === coIndex
                  ? {
                      ...component,
                      option: [...(component.option || []), ""] // Add an empty option
                    }
                  : component
              )
            }
          : field
      );
    });
  };

  const handleOptionSelect = (event, optIndex) => {
    const { value } = event.target;
    setFormFields(prevFields => {
      return prevFields.map((field, fieldIndex) => 
        fieldIndex === index
          ? {
              ...field,
              components: field.components.map((component, compIndex) => 
                compIndex === coIndex
                  ? { 
                      ...component, 
                      option: component.option.map((opt, oIndex) => 
                        oIndex === optIndex ? value : opt
                      )
                    }
                  : component
              )
            }
          : field
      );
    });
  };


  const SelectTextFieldProps = (
    <Grid item xs={12} spacing={2} >
        <p>Options</p>
        {
          formFields[index]?.components[coIndex]?.option?.map((res, optindex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, mb: 1}} >
                  <TextField sx={{m: 1}} key={index} variant="outlined" name="option" value={formFields[index]?.components[coIndex]?.option[optindex]} fullWidth size="small" placeholder='Option' onChange={(e) => handleOptionSelect(e, optindex)}/>
                   <IconButton onClick={() => handleDeleteOption(optindex)} color="error" >
                        <Iconify icon="solar:trash-bin-2-bold-duotone" />
                  </IconButton>
              </Stack>

            )
          }) 
        }

    <Button variant="contained" color="primary" onClick={handleAddOption}>
      Add Option
    </Button>
        
    </Grid>
  )

// HANDLE RADIO BUTTON
const handleRadioGroup = (event, optIndex, fieldName) => {
  const { value } = event.target;

  setFormFields(prevFields => {
    return prevFields.map((field, fieldIndex) => 
      fieldIndex === index
        ? {
            ...field,
            components: field.components.map((component, compIndex) => 
              compIndex === coIndex
                ? { 
                    ...component, 
                    option: component.option.map((opt, oIndex) => 
                      oIndex === optIndex ? { ...opt, [fieldName]: value } : opt
                    )
                  }
                : component
            )
          }
        : field
    );
  });
};



  const RadioButtonProps = (
    <Grid item xs={12} spacing={2} >

    <FormControlLabel control={
                    <Checkbox
                        name="isRow"
                        checked={!!formFields[index]?.components[coIndex]?.isRow} 
                        onChange={(e) => handleChangeChecked(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label="isRow?" />
        <p>Options</p>
        {
          formFields[index]?.components[coIndex]?.option?.map((res, optindex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, mb: 1}} >
                  <TextField sx={{m: 1}} key={index} variant="outlined" name="option" value={res?.value} fullWidth size="small" placeholder='Radio Value' onChange={(e) => handleRadioGroup(e, optindex, "value")}/>
                  <TextField sx={{m: 1}} key={index} variant="outlined" name="option" value={res?.label} fullWidth size="small" placeholder='Radio Label' onChange={(e) => handleRadioGroup(e, optindex, "label")}/>
                   <IconButton onClick={() => handleDeleteOption(optindex)} color="error" >
                        <Iconify icon="solar:trash-bin-2-bold-duotone" />
                  </IconButton>
              </Stack>

            )
          }) 
        }

    <Button variant="contained" color="primary" onClick={handleAddOption}>
      Add Option
    </Button>
        
    </Grid>
  )


  const CheckboxProps = (
    <Grid item xs={12} spacing={2} >

    <FormControlLabel control={
                    <Checkbox
                        name="isRow"
                        checked={!!formFields[index]?.components[coIndex]?.isRow} 
                        onChange={(e) => handleChangeChecked(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label="isRow?" />
        <p>Options</p>
        {
          formFields[index]?.components[coIndex]?.option?.map((res, optindex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, mb: 1}} >
                  <TextField sx={{m: 1}} key={index} variant="outlined" name="option" value={res?.value} fullWidth size="small" placeholder='Radio Value' onChange={(e) => handleRadioGroup(e, optindex, "value")}/>
                  <TextField sx={{m: 1}} key={index} variant="outlined" name="option" value={res?.label} fullWidth size="small" placeholder='Radio Label' onChange={(e) => handleRadioGroup(e, optindex, "label")}/>
                   <IconButton onClick={() => handleDeleteOption(optindex)} color="error" >
                        <Iconify icon="solar:trash-bin-2-bold-duotone" />
                  </IconButton>
              </Stack>

            )
          }) 
        }

    <Button variant="contained" color="primary" onClick={handleAddOption}>
      Add Option
    </Button>
        
    </Grid>
  )



  
  return (
    <Dialog fullScreen open={open} onClose={onClose} {...other}
    PaperProps={{
        sx: {
          position: "fixed",
          right: 0,
          top: 0,
          height: "100vh",
          width: "50vw",
          margin: 0,
          borderRadius: "0px",
        },
      }}
    >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
             Component Properties
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>{
            formFields[index]?.components?.length !== 0 ? (
                <Grid container spacing={2}>
                <Grid item xs={12}>
                   <TextField value={formFields[index]?.components[coIndex]?.type} variant="outlined" fullWidth size="small" placeholder='type' disabled  />
                </Grid>
                <Grid item xs={12}>
                <TextField variant="outlined" name="name" value={formFields[index]?.components[coIndex]?.name} fullWidth size="small" placeholder='Text Input Name' onChange={(e) => handleChange(e)}/>
                </Grid>

                 {/*========== LABEL =============*/}
                  {
                        formFields[index]?.components[coIndex]?.type === 'label' ?
                            <Grid item xs={12}>
                                <TextField multiline rows={3} sx={{ textarea: { resize: "both" } }} variant="outlined" name="label" value={formFields[index]?.components[coIndex]?.label} fullWidth size="small" placeholder='Placeholder' onChange={(e) => handleChange(e)}/>
                            </Grid>
                        :
                        <Grid item xs={12}>
                            <TextField variant="outlined" name="label" value={formFields[index]?.components[coIndex]?.label} fullWidth size="small" placeholder='Placeholder' onChange={(e) => handleChange(e)}/>
                        </Grid>
                  }
    
                <Grid item xs={12}>
                <FormControlLabel control={
                    <Checkbox
                        name="required"
                        checked={!!formFields[index]?.components[coIndex]?.required} 
                        onChange={(e) => handleChangeChecked(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label="isRequired?" />
                   <FormControlLabel control={
                    <Checkbox
                        name="showIntable"
                        checked={!!formFields[index]?.components[coIndex]?.showIntable} 
                        onChange={(e) => handleChangeChecked(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label="Display in Table?" />
         
                </Grid>
                  {/*========== MULTI TEXTBOX =============*/}
                    {
                        formFields[index]?.components[coIndex]?.type === 'multiInput' ?
                          MultiTextField
                        :
                        null
                    }

                  {/*========== SELECT TEXT FIELD =============*/}
                    {
                        formFields[index]?.components[coIndex]?.type === 'select' ?
                          SelectTextFieldProps
                        :
                        null
                    }

                   {/*========== RADIO BUTTON =============*/}
                                    {
                        formFields[index]?.components[coIndex]?.type === 'radio' ?
                         RadioButtonProps
                        :
                        null
                    }

                   {/*========== CHECKBOX  =============*/}
                                       {
                        formFields[index]?.components[coIndex]?.type === 'checkbox' ?
                         CheckboxProps
                        :
                        null
                    }

               </Grid>
                )
                : null
            }

        </DialogContent>

        <DialogActions >
            <Button variant="outlined" color="inherit" onClick={onClose}>
                        Save
           </Button>
                   {/* <Button autoFocus color="inherit" onClick={onClose}>
              Close
            </Button> */}
        </DialogActions>


    
      </Dialog>
  );
}