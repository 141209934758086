import * as React from 'react';
import {Button, Dialog, DialogContent, DialogActions, AppBar, Toolbar, IconButton, Slide, TextField, FormControlLabel, Radio, RadioGroup, Typography, Grid} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function FormBuilderProperties({title, content, action, open, section, index, formFields, setFormFields, onClose, ...other }) {

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields(prevFields => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
          ...updatedFields[index], 
          [name]: value 
      };
      return updatedFields;
  });
  }
  
  return (
    <Dialog fullScreen open={open} onClose={onClose} {...other}
    PaperProps={{
        sx: {
          position: "fixed",
          right: 0,
          top: 0,
          height: "100vh",
          width: "50vw",
          margin: 0,
          borderRadius: "0px",
        },
      }}
    >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Section Properties
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
             <Grid container spacing={2}>
             <Grid item xs={12}>
                <TextField value={formFields[index]?.type} variant="outlined" fullWidth size="small" placeholder='type' disabled  />
             </Grid>
             <Grid item xs={12}>
             <TextField variant="outlined" name="name" value={formFields[index]?.name} fullWidth size="small" placeholder='Section Name' onChange={(e) => handleChange(e)} disabled/>
             </Grid>
             <Grid item xs={12}>
             <TextField variant="outlined" name="label" value={formFields[index]?.label} fullWidth size="small" placeholder='Section Label' onChange={(e) => handleChange(e)}/>
             </Grid>
             <Grid item xs={12}>
                <Typography variant='body1'>Column Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <RadioGroup name="column" row value={formFields[index].column} onChange={(e) => handleChange(e)}>
                    <FormControlLabel
                        value="12"
                        control={
                        <Radio 
                            icon={  <Iconify icon="tabler:columns-1" />} 
                            checkedIcon={  <Iconify icon="tabler:columns-1" />} 
                        />
                        }
                        label="Fullwidth"
                    />
                    <FormControlLabel
                        value="6"
                        control={
                        <Radio 
                            icon={  <Iconify icon="tabler:columns-2" />} 
                            checkedIcon={  <Iconify icon="tabler:columns-2" />} 
                        />
                        }
                        label="2 Columns"
                    />
                    <FormControlLabel
                        value="4"
                        control={
                        <Radio 
                            icon={  <Iconify icon="tabler:columns-3" />} 
                            checkedIcon={  <Iconify icon="tabler:columns-3" />} 
                        />
                        }
                        label="3 Columns"
                    />
                    <FormControlLabel
                        value="3"
                        control={
                        <Radio 
                            icon={  <Iconify icon="lucide:columns-4" />} 
                            checkedIcon={  <Iconify icon="lucide:columns-4" />} 
                        />
                        }
                        label="4 Columns"
                    />

                  <FormControlLabel
                        value="2"
                        control={
                        <Radio 
                            icon={  <Iconify icon="lucide:columns-4" />} 
                            checkedIcon={  <Iconify icon="lucide:columns-4" />} 
                        />
                        }
                        label="6 Columns"
                    />


                </RadioGroup>
            </Grid>
            </Grid>
        </DialogContent>

        <DialogActions >
            <Button variant="outlined" color="inherit" onClick={onClose}>
                        Save
           </Button>
                   {/* <Button autoFocus color="inherit" onClick={onClose}>
              Close
            </Button> */}
        </DialogActions>


    
      </Dialog>
  );
}