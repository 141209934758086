
import { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import { Button, Box, Stack, Card, Typography, IconButton, Grid, Container, Tooltip, CircularProgress, useTheme } from "@mui/material";
import { useSettingsContext } from 'src/components/settings/index.js';
import { useBoolean } from '../../../hooks/use-boolean.js';
import { FormBuilderDialog } from '../../../components/form-builder/index.js';
import { useSnackbar } from 'src/components/snackbar/index.js';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/index.js';
import Iconify from 'src/components/iconify/iconify.js';

// GRAPHQL
import { CREATE_CUSTOMFORM, UPDATE_CUSTOMFORM, GET_CUSTOMFORM } from "src/graphql/modules/_client/customForm.js";
import { useMutation, useQuery } from "@apollo/react-hooks";



export default function FormBuilder() {
  const settings = useSettingsContext();
  const {fullName} = useSelector((state) => state?.auth?.user);
  const theme = useTheme();
  const navigate = useNavigate();
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.databaseName);
  const _companyName  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const [formFields, setFormFields] = useState([]);
  const [results, setResults] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [formId, setFormId] = useState("");
  const confirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();
  const [CreateCustomFormMutation] =  useMutation(CREATE_CUSTOMFORM);
  const [UpdateCustomFormMutation] =  useMutation(UPDATE_CUSTOMFORM);
  

  const { data, refetch } = useQuery(GET_CUSTOMFORM, {
    variables: {
      companyName: company_name,
      encryptionkey: ""
      },
      fetchPolicy: "cache-and-network",
    });

 

    useEffect(() => {
        if(data){
          setResults(data?.getCustomForm)
        }
    }, [data])

  const handleSave = async() => {
    try {
      const firstWord = formTitle.split(" ")[0];
      const rand = _.random(0, 99);
      const {data: { createCustomForm }} = await CreateCustomFormMutation({
        variables: {
          companyName: company_name,
          fullName: fullName,
          encryptionkey: "",
            data: {
              title:  formTitle,
              description: "This is custom form.",
              formModel: `newForm_${firstWord}_${rand}`,
              section: formFields,
              createdBy: fullName,
              createdDate: new Date(),
              modifiedBy: fullName,
              modifiedDate: new Date()
            }
          }
      })
      if(createCustomForm?.success){
        enqueueSnackbar('Create success!', {variant: 'success'});
        refetch();
      }else {
        enqueueSnackbar('Failed to created', {variant: 'error'});
      }

    } catch(err){
      console.log(err)
    }
  }

  const handleUpdate = async() => {
    try {
      const {data: { updateCustomForm }} = await UpdateCustomFormMutation({
        variables: {
          _id: formId,
          companyName: company_name,
          fullName: fullName,
          encryptionkey: "",
            data: {
              title:  formTitle,
              description: "This is custom form.",
              section: formFields,
              modifiedBy: fullName,
              modifiedDate: new Date()
            }
          }
      })
      if(updateCustomForm?.success){
        enqueueSnackbar('Update success!', {variant: 'success'});
        refetch();
      }else {
        enqueueSnackbar('Failed to update', {variant: 'error'});
      }

    } catch(err){
      console.log(err)
    }
  }

  const handleChangeTitle = (event) => {
    const {value} = event.target;
    setFormTitle(value)
  }

  const navigateToForm = useCallback((data) => {
      navigate(`/${company_name}/auditing/${data?._id}`, {state: {data}})
  });


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                 <CustomBreadcrumbs
                 heading={`${_companyName} - Auditing Form`}
                 links={[
                  {name: 'Dashboard'},
                  {name: 'Auditing'}
                 ]}
                 action={
                  <Button onClick={() => confirm.onTrue()}
                   variant="contained"
                    startIcon={<Iconify icon="mingcure:add-line" />}
                  >Forms</Button>
                 }
                />

          

                  <Grid container spacing={2}>
                    {
                      results?.results?.map((res, index) => {
                        return(
                          <Grid item sm={12} md={4}>
                          <Card sx={{ display: 'flex', alignItems: 'center', p: 3}}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="title1">{res?.title}</Typography>
                            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
                            </Stack>
                            </Box>
                            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
                            <Tooltip title={res?.title}>
                            <IconButton onClick={() => navigateToForm(res)}>
                              <Iconify icon={'fluent:form-20-regular'} width={32} sx={{ color: theme.palette.info.main, position: 'absolute' }} />
                                <CircularProgress
                                  variant="determinate"
                                  value={100}
                                  size={56}
                                  thickness={2}
                                  sx={{ color: theme.palette.info.main, opacity: 0.48 }}
                                />
                            </IconButton>
                            </Tooltip>
                            </Stack>
                      </Card>
                      </Grid>
                        )
                      })
                    }

                </Grid>

           <FormBuilderDialog
              open={confirm.value}
               onClose={confirm.onFalse}
              title="Create Auditing Form"
              content={`Are you sure want to delete?`}
              setFormFields={setFormFields}
              formFields={formFields}
              customForms={results}
              formTitle={formTitle}
              handleChangeTitle={handleChangeTitle}
              setFormTitle={setFormTitle}
              refetchQuery={refetch}
              setFormId={setFormId}
               action={
                <Button variant="contained" color="info" onClick={handleSave} >
                  Save
               </Button>
             }
             actionUpdate={
              <Button variant="contained" color="info" onClick={handleUpdate} >
                 Update
            </Button>
             }
            />
      </Container>
  );
}
