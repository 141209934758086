import gql from "graphql-tag";

export const GET_AUDITINGFORM = gql`
query GetAuditingForm($companyName: String!, $formModel: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getAuditingForm(companyName: $companyName, formModel: $formModel, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;


export const CREATE_AUDITINGFORM = gql`
mutation CreateAuditingForm($data: InputAuditingForm!, $fullName: String!, $companyName: String!, $formModel: String!) {
  createAuditingForm(data: $data, fullName: $fullName, companyName: $companyName, formModel: $formModel) {
    code
    success
    message
  }
}`;

export const UPDATE_AUDITINGFORM = gql`
mutation UpdateAuditingForm($_id: ID!, $data: InputAuditingForm!, $fullName: String!, $companyName: String!, $formModel: String!) {
  updateAuditingForm( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName, formModel: $formModel) {
    code
    success
    message
  }
}`;


export const DELETE_AUDITINGFORM = gql`
mutation DeleteAuditingForm($_id: ID!, $fullName: String!, $companyName: String!, $formModel: String!) {
  deleteAuditingForm(_id: $_id, fullName: $fullName, companyName: $companyName, formModel: $formModel) {
    code
    success
    message
  }
}`;



