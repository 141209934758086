import { sum } from 'lodash';
import { fCurrency } from './format-number.js';

const customHeaders = {
    "Course Run Id": "courseRunId",
    "Status": "status",
    "Course Start Date": "courseDates.start", 
    "Course End Date": "courseDates.end", 
    "Class Code": "classCode",
    "Course Title": "course.courseTitle",
    "Training Venue": "venue",
    "Assessors": "assessors",
    "Schedule Info": "scheduleInfo",
    "Course Vacany": "courseVacancy",
    "Trainee": "enrolment",
    "Reg Date Opening": "registrationDates.opening",
    "Reg Date Closing": "registrationDates.closing",  
    "Created By": "createdBy",
    "Modified By": "modifiedBy"
  };

function exportToClass(jsonData, filename = 'export.csv') {
  const headers = Object.keys(customHeaders);
  // Map JSON data to include only the fields matching the custom headers
  const csvRows = jsonData.map(row =>
    headers.map(header => {
      const field = customHeaders[header];
      let value;


      // Handle date formatting specifically
      if (field === "courseDates.start") {
        value = row?.courseDates?.start ? new Date(row?.courseDates?.start).toLocaleDateString('en-SG') : ''
      }else if (field === "courseDates.end") {
        value = row?.courseDates?.end ? new Date(row?.courseDates?.end).toLocaleDateString('en-SG') : ''
      }else if (field === "registrationDates.opening") {
        value = row?.registrationDates?.opening ? new Date(row?.registrationDates?.opening).toLocaleDateString('en-SG') : ''
      }else if (field === "registrationDates.closing") {
        value = row?.registrationDates?.closing ? new Date(row?.registrationDates?.closing).toLocaleDateString('en-SG') : ''
      } else if(field === "venue"){
        const venueDetails = row?.venue?.block || row?.venue?.postalCode ? `${row?.venue?.block} ${row?.venue?.street} #${row?.venue?.floor}-${row?.venue?.unit} ${row?.venue?.room} ${row?.venue?.building} ${row?.venue?.postalCode}` : '';
        value = row?.venue ? venueDetails: ''
      }else if (field === "assessors"){
        const setTrainer = row?.assessors?.map(item => item?.fullName)
        const filterTrainer = [...new Set(setTrainer)];
        value = filterTrainer.join(", ")
      }else if (field === "enrolment"){
        console.log(row?.enrolment)
        const setTrainee = row?.enrolment?.map(item => `${item?.traineeInfo?.fullName} (${item?.traineeInfo?.nricFin}) - ${item?.ssgenrolment?.status}`)
        console.log(setTrainee)
        const filterTrainee = [...new Set(setTrainee)];
        value = filterTrainee.join(", ")
      } else {
        // Access nested fields or flat fields
        value = field.split('.').reduce((acc, key) => acc && acc[key], row) || '';
      }

      return `"${value}"`;
    }).join(',')
  );

  // Combine custom headers and rows into a CSV string
  const csvContent = [headers.join(','), ...csvRows].join('\n');

  // Create a Blob and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
    exportToClass
}

