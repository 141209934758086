import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
// @mui
import {Button, Dialog, DialogActions, Container, Grid, Typography, Stack, MenuItem, FormControl, InputLabel, Select, IconButton, TextField} from '@mui/material';
import { DndContext, useDraggable } from "@dnd-kit/core";
import Iconify from 'src/components/iconify/iconify';
import {DroppableCanvas, formElements} from './form-builder-preview.js';


import { GET_CUSTOMFORM_BY_ID, DELETE_CUSTOMFORM } from "src/graphql/modules/_client/customForm.js";
import { useQuery, useMutation } from "@apollo/react-hooks";

// ----------------------------------------------------------------------

// const formElements = [
//   { id: "label", label: "Label", icon: "bi:textarea-t" },
//   { id: "input", label: "Text Input", icon: "solar:text-field-bold-duotone" },
//   { id: "datepicker", label: "Date Picker", icon: "solar:calendar-date-bold-duotone" },
//   { id: "checkbox", label: "Checkbox", icon: "material-symbols:select-check-box" },
//   { id: "radio", label: "Radio Button", icon: "ri:radio-button-line" },
// ];

const DraggableItem = ({ id, label, icon }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({ id });
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
     <Button startIcon={<Iconify icon={icon} />}>{label}</Button>
    </div>
  );
};


export default function FormBuilderDialog({ title, content, action, actionUpdate, setFormFields, setFormId, customForms, formFields, handleChangeTitle, formTitle, setFormTitle, refetchQuery,  open, onClose, ...other }) {
   const [selectForm, setSelectForm] = useState("newform");
   const {fullName} = useSelector((state) => state?.auth?.user);
   const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.databaseName);
   const [DeleteCustomFormMutation] =  useMutation(DELETE_CUSTOMFORM);
    //  const [results, setResults] = useState(null);

     const { data } = useQuery(GET_CUSTOMFORM_BY_ID, {
       variables: {
         companyName: company_name,
         encryptionkey: "",
         _id: selectForm
         },
         fetchPolicy: "cache-and-network",
      });

      useEffect(() => {
          if(data){
              if(selectForm !== 'newform'){
                const section = data?.getCustomFormById?.results?.section;
                const title = data?.getCustomFormById?.results?.title;
                setFormFields(section)
                setFormTitle(title)
              }else {
                setFormFields([])
                setFormTitle("")
              }
          }
      }, [data])
   
       

   const handleDragEnd = (event) => {
    const { active, over } = event;
    const element = formElements.find((item) => item.id === active.id);

    if (element) {
      if (over && over.id.startsWith("section-")) {
        const sectionIndex = parseInt(over.id.split("-")[1], 10);
        const updatedFields = [...formFields];
        updatedFields[sectionIndex].components.push({ type: element.id, label: element.label, name: element.name, required: element?.required, rows: element?.rows, option: element?.option, isRow: element?.isRow, showIntable: element?.showIntable });
        setFormFields(updatedFields);
      } 
    }
  };

  const addSection = () => {
    setFormFields([...formFields, { type: "section", label: "New Section", name:"section", column: "6", components: [] }]);
  };

  const handleChangeForm = (event) => {
      const {name, value} = event.target;
      setSelectForm(value)
      setFormId(value)
  }

  const handleDeleteForm = async(event) => {
    try{
      const {data: { deleteCustomForm }} = await DeleteCustomFormMutation({
        variables: {
          companyName: company_name,
          fullName: fullName,
          encryptionkey: "",
          _id: selectForm
          }
      })
      if(deleteCustomForm?.success){
        setFormFields([]);
        setSelectForm("");
        setFormTitle("");
        refetchQuery();
      }
    }catch (err){
      console.log(err)
    }
  }

 



  return (
        <DndContext onDragEnd={handleDragEnd}>
    <Dialog fullWidth fullScreen open={open} onClose={onClose} {...other}>
      {/* <DialogTitle sx={{ pb: 2 }}>
       
        <Typography variant='h6'>{title}</Typography>
        </DialogTitle> */}
      <Container maxWidth="xl" style={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item md={2} sx={{ overflow: "auto", maxHeight: "100vh" }}>
                <Typography variant='h6'>Components</Typography>
                {/* <Grid item md={6}><Button startIcon={<Iconify icon="bi:textarea-t" />}>Label</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="solar:text-field-bold-duotone" />}>TextBox</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}>DatePicker</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="material-symbols:select-check-box" />}>Select</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="ri:radio-button-line" />}>Radio</Button></Grid>
                <Typography variant='h6'>Layout</Typography>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-two-16-regular" />}>Two Column</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-three-16-regular" />}>Three Column</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-four-24-regular" />}>Four Column</Button></Grid> */}

                <div className="mt-4 space-y-2">
                {formElements.map((item) => (
                  <DraggableItem key={item.id} id={item.id} label={item.label} icon={item.icon}/>
                ))}
              </div>

        </Grid>
        <Grid item md={10}
                sx={{
                  overflow: "auto",
                  height: "100vh",// Debugging border
                }}
        >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, mb: 1}} >
          <FormControl fullWidth>
              <InputLabel id="simple-select-label">Select Form</InputLabel>
              <Select
                labelId="simple-select-label"
                id="demo-simple-select"
                label="Select Form"
                size='small'
                onChange={handleChangeForm}
                name='selectForm'
                value={selectForm}
              > 
                <MenuItem value="newform">New Form</MenuItem>
                {customForms?.results?.map((res) => {
                  return(
                    <MenuItem value={res?._id}>{res?.title}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
             <TextField  variant="outlined" name="formTitle" value={formTitle} fullWidth size="small" placeholder='Form Title' onChange={handleChangeTitle}/>
            <IconButton onClick={handleDeleteForm} color="error" hidden={selectForm !== 'newform' ? false : true} >
                        <Iconify icon="solar:trash-bin-2-bold-duotone" />
              </IconButton>
            </Stack>

            <DroppableCanvas formFields={formFields} setFormFields={setFormFields} addSection={addSection}/>
        </Grid>
      </Grid>
              <DialogActions  >
                  {selectForm !== 'newform'  ? actionUpdate : action}
              <Button variant="outlined" color="inherit" onClick={onClose}>
                Close
              </Button>
              </DialogActions>
      </Container>



    </Dialog>
    </DndContext>
  );
}

FormBuilderDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  formTitle: PropTypes.string,
  handleChangeTitle: PropTypes.func,
  refetchQuery: PropTypes.func,
  actionUpdate: PropTypes.node,
};
