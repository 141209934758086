import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { isValid, parseISO } from 'date-fns';


// @mui
import {AppBar, Toolbar, IconButton, Button, Dialog, DialogActions, Container, Grid, Typography, Stack, MenuItem, FormControl, Card, FormGroup, Checkbox, FormLabel, RadioGroup, Radio, FormControlLabel, TextField} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'src/components/iconify/iconify';
import ESignature from '../../../util/eSignature.jsx';
import { useBoolean } from '../../../hooks/use-boolean.js';

const Section = ({ section, dataField, handleChange, eSign, setESignName }) => {

    return (
        <>
      <Card className="p-4 bg-gray-200 shadow rounded">
      <Typography variant="h6">{section.label}</Typography>
        <Grid container spacing={2}>
          {section?.components?.map((field, compIndex) => {

            const fieldValue = dataField?.section?.[field.name] ?? "";
            return (
            <Grid item xs={section?.column} key={compIndex}>
                  <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >
                {field.type === "label" && <Typography variant="outlined" fullWidth size="small" placeholder={field.label}>{field.label}</Typography>}

                {field.type === "input" && 
                <TextField variant="outlined" 
                name={field.name} 
                fullWidth size="small"  
                placeholder={field.label + (field?.required ? " *" : "")} 
                required={field?.required} 
                value={fieldValue}
                onChange={(e) => handleChange(section.name, e.target.name, e.target.value)}
                />}

                {field.type === "multiInput" && 
                <TextField multiline 
                variant="outlined" 
                name={field.name} 
                rows={field.rows} 
                fullWidth 
                size="small" 
                placeholder={field.label + (field?.required ? " *" : "")} 
                onChange={handleChange} 
                value={fieldValue}
                />}

                {field.type === "select" && 
                      <TextField 
                      select 
                      variant="outlined" 
                      fullWidth size="small" 
                      placeholder={field.label + (field?.required ? " *" : "")}
                      required={field?.required} 
                      value={fieldValue}
                      onChange={(e) => handleChange(section.name, field.name, e.target.value)}
                      >
                          {
                            field?.option?.map((res, ind) => (
                              <MenuItem key={ind} value={res}>{res}</MenuItem>
                            ))
                          }
                      </TextField>
                  }

                {/* {field.type === "checkbox" && 
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                         <FormLabel component="legend">{field?.label}</FormLabel>
                         <FormGroup name={field?.name}  row={field?.isRow ? true : false}>
                          {
                            field?.option?.map((res, ind) => (
                              <FormControlLabel key={ind} control={ <Checkbox 
                              checked={dataField?.[section?.name]?.[field.name]?.includes(res)}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? [...(dataField?.[section?.name]?.[field.name] || []), res]
                                  : (dataField?.[section?.name]?.[field.name] || []).filter(val => val !== res);
                                handleChange(section.name, field.name, newValue);
                              }}
                              /> } label={res?.label} />
                            ))
                          }
                       
                         </FormGroup>
                    </FormControl>
                } */}
                {field.type === "checkbox" && (
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">{field?.label}</FormLabel>
                    <FormGroup name={field?.name} row={field?.isRow ? true : false}>
                      {field?.option?.map((res, ind) => {
                        const isChecked = dataField?.[section?.name]?.[field.name]?.includes(res.value); // Ensure `res.value` is checked in the array
                        return (
                          <FormControlLabel
                            key={ind}
                            control={
                              <Checkbox
                                checked={isChecked} // Check if the value exists in the array for that field
                                onChange={(e) => {
                                  const newValue = e.target.checked
                                    ? [...(dataField?.[section?.name]?.[field.name] || []), res.value] // Add value if checked
                                    : (dataField?.[section?.name]?.[field.name] || []).filter(val => val !== res.value); // Remove value if unchecked

                                  handleChange(section.name, field.name, newValue); // Call the handleChange to update the state
                                }}
                              />
                            }
                            label={res.label}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                )}


                {field.type === "radio" &&
                    <FormControl>
                    <FormLabel id="radio-buttons-group-label">{field?.label}</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      name={field?.name}
                      row={field?.isRow ? true : false}
                      onChange={(e) => handleChange(section.name, field.name, e.target.value)}
                      value={fieldValue}
                    >
                      {
                        field?.option?.map((res, ind) => (
                          <FormControlLabel value={res?.value} control={<Radio />} label={res?.label} />
                        ))
                      }
                    
                    </RadioGroup>
                  </FormControl>

                }
                {field.type === "button" && <Button variant="contained" fullWidth>{field.label}</Button>}
                {field.type === "datepicker" &&
                <DatePicker
                  name={field?.name}
                  label={field.label}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      fullWidth: true
                    },
                  }}
                  sx={{width: 410}}
                  value={
                    fieldValue
                      ? (fieldValue instanceof Date
                          ? fieldValue
                          : isValid(parseISO(fieldValue)) ? parseISO(fieldValue) : null)
                      : null
                  }
                  onChange={(date) =>  {
                    handleChange(section.name, field.name, date)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />}

              {field.type === "esignature" && 
              <>
              <Typography variant="outlined" fullWidth size="small" placeholder={field.label}>{field.label}</Typography>
              {
                fieldValue &&
                <img src={fieldValue} alt="Description of image" width="200" style={{ border: "1px solid"}}/>
              }
              <Button startIcon={<Iconify icon={"ph:signature-fill"} />} onClick={() =>{
                setESignName(field.name)
                 eSign.onTrue();
              }}>eSign</Button>


              </>
              }
                </Stack>
            </Grid>
            )}
          )}
        </Grid>
      </Card>
      </>
    );
  };


export default function AuditingFormDialog({ title, content, action, section, dataToUpdate, actionUpdate, isCreate, handleChange, open, onClose, ...other}) {
  const [signature, setSignature] = useState(null);
  const [esignName, setESignName] = useState(null);
  const eSign = useBoolean();
  
  // const dataCreateUpdate = section?.map((section) => ({
  //   ...section,
  //   components: section.components?.map((component) => ({
  //     ...component,
  //     value: dataToUpdate?.section[component.name] ?? "" // Map each field value
  //   })) || [],
  // })) || [];

return (
    <Dialog fullWidth fullScreen open={open} onClose={onClose} {...other}>
              <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Iconify icon="solar:close-circle-bold-duotone" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>

        <Container maxWidth="xl" style={{ padding: 20 }}>
            <Grid container spacing={2}>
                {section?.map((field, index) => (
                <Grid item xs={12} key={index}>
                    {field.type === "section" ? (
                     <Section 
                     section={field} 
                     dataField={dataToUpdate}
                     handleChange={(sectionName, fieldName, value) => handleChange(sectionName, fieldName, value)}
                     eSign={eSign}
                     setESignName={setESignName}
                     /> 
                    ) : null}
                 </Grid>
                ))}

            </Grid>
        <DialogActions  >
                 {isCreate ? action  : actionUpdate}
              <Button variant="outlined" color="inherit" onClick={onClose}>
                Close
              </Button>
              </DialogActions>

              <ESignature 
                    title="e-Sign"
                    open={eSign.value} 
                    onClose={eSign.onFalse}
                    setSignature={setSignature}
                    action={
                      <Button variant="outlined" color="inherit" onClick={()=>  {
                        handleChange('section', esignName, signature);
                        eSign.onFalse();
                      }}>
                      Save
                    </Button>
                    }
                />
 


        </Container>
    </Dialog>
)   
}
